<div class="container-fluid">
  <div class="row" style="margin: 10px auto">
    <!---starting mat tabs -->
    <div class="col-lg-12 col-xl-12 mat-elevation-z8" style="text-align: center">
      <mat-tab-group mat-stretch-tabs [(selectedIndex)]="tabIndex" (selectedTabChange)="getData($event)">
        <mat-tab label="Academic Course">
          <div class="card" style="padding: 30px; position: relative; background-color: #f2f3f4">
            <div>
              <form [formGroup]="secondFormGroup">
                <div class="row">
                  <div class="col-sm-4">
                    <mat-form-field style="width: 100%">
                      <mat-label>State</mat-label>
                      <mat-select (selectionChange)="setState($event.value)" required formControlName="idState">
                        <mat-option *ngFor="let state of stateData" [value]="state.idState">
                          {{ state.state}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-4">
                    <mat-form-field style="width: 100%">
                      <mat-label>Syllabus</mat-label>
                      <mat-select (selectionChange)="setSyllabus($event.value)" required>
                        <mat-option *ngFor="let syllabus of syllabusData" [value]="syllabus.idSyllabus">
                          {{ syllabus.syllabusName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="classStandardFlag" class="col-sm-4">
                    <mat-form-field style="width: 100%">
                      <mat-label>Class Standard</mat-label>
                      <mat-select required (selectionChange)="getSubjectsByClassStandard($event.value)" formControlName="classStandard">
                        <mat-option *ngFor="let class of classData" [value]="class.idClassStandard">
                          {{ class.classStandadName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                
                <div class="row">
                  <div *ngIf="academicSubjectFlag" class="col-sm-4">
                    <div>
                      <mat-form-field style="width: 100%">
                        <mat-label>Subjects</mat-label>
                        <mat-select required (selectionChange)="getSubjects($event.value)" formControlName="subject">
                          <mat-option *ngFor="let subject of subjectData" [value]="subject.idSubject">
                            {{ subject.subjectName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="subRating" >
                      <label style="display: flex;">Subject Rating : {{subRating}}</label>
                     
                    </div>
                  </div>
               
                  <div *ngIf="chapterFlag" class="col-sm-4">
                    <div>
                      <mat-form-field style="width: 100%">
                        <mat-label>Chapter</mat-label>
                        <mat-select (selectionChange)="getAcademeicVideo($event.value)" required formControlName="chapter">
                          <mat-option *ngFor="let chapter of chapterData" [value]="chapter.idSubjectChapter">
                            {{ chapter.chapterName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="chapterRating">
                      <label style="display: flex;">Chapter Rating : {{chapterRating }}</label>
                      
                    </div>
                  </div>
                 
                </div>
                
               
              </form>
            </div>
          </div>

          <div>
            <div *ngIf="showAcademicVideoFlag">
              <div style="text-align: center">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Sequencing</th>
                      <th scope="col">Question</th>
                      <th scope="col">Topic</th>
                      <th scope="col">Duration</th>
                      <th scope="col">Status</th>
                      <th scope="col">Video Rating</th>
                      <th scope="col">Action</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of dataSource | paginate: config">
                      <td>{{ item.offlineCourse.videoSeqNumber }}</td>
                      <td title="Click to Stream this video." (click)="getVidePlayer(item.offlineCourse)" scope="row">
                        <u mathjax>{{ item.offlineCourse.question }}</u> 
                      </td>
                      <td>{{ item.offlineCourse.topic }}</td>
                      <td>{{ item.offlineCourse.videoDuration }} sec</td>
                      <td>{{item.offlineCourse.status}}</td>
                      <td class="link-div" (click)="routeToReviewDetails(item.offlineCourse.idOfflineVideoCourse)" style="text-align: center;">
                        {{item.rating}}({{item.totalRating}})
                      </td>
                      <td>
                        <button   (click)="editAcademicVideo(item.offlineCourse)" mat-icon-button title="Edit academic video">
                          <mat-icon>border_color</mat-icon>
                        </button>
                      </td>
                      <td>
                        <button mat-icon-button color="warn"    (click)="deleteVideo(item.offlineCourse.idOfflineVideoCourse)"title="Delete academic video">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
              </div>
            </div>

            <!-- 
            <div *ngIf="!showAcademicVideoFlag">
              <h2
                style="
                  align-items: center;
                  align-content: center;
                  text-align: center;
                "
              >
                Its Look Like You have not uploaded any video for this chapter.
              </h2>
            </div>
                        -->
          </div>
        </mat-tab>
        <mat-tab label="ExtraCurricular  Course">
          <div
            class="card"
            style="padding: 30px; position: relative; background-color: #f2f3f4"
          >
            <div class="row">
              <!-- <div class="col-sm-4">
                <mat-form-field style="width: 100%">
                  <mat-label>Category</mat-label>
                  <mat-select
                    (selectionChange)="getExtraProduct($event.value)"
                    required
                  >
                    <mat-option
                      *ngFor="let pg of productGroupData"
                      [value]="pg.idProductGroup"
                    >
                      {{ pg.extraCurrCategory }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->

              <div  class="col-sm-4">
                <mat-form-field style="width: 100%">
                  <mat-label>Subject</mat-label>
                  <mat-select
                    required
                    (selectionChange)="getSubjectsChapter($event.value)"
                  >
                    <mat-option
                      *ngFor="let product of productData"
                      [value]="product"
                    >
                      {{ product.subjectName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div *ngIf="extraChapterFlag" class="col-sm-4">
                <mat-form-field style="width: 100%">
                  <mat-label>Chapter</mat-label>
                  <mat-select
                    (selectionChange)="getExtraVideo($event.value)"
                    required
                  >
                    <mat-option
                      *ngFor="let chapter of extraChapterData"
                      [value]="chapter.idSubjectChapter"
                    >
                      {{ chapter.chapterName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div>
              <div *ngIf="showExtraVideoFlag">
                <div style="text-align: center">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Sequencing</th>
                        <th scope="col">Video Id</th>

                        <th scope="col">Topic</th>

                        <th scope="col">Duration</th>
                        <th scope="col">Description</th>

                        <th scope="col">Action</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let item of extraVideoData | paginate: config2"
                      >
                        <td>{{ item.offlineCourse.videoSeqNumber }}</td>
                        <td
                          title="Click to Stream this video."
                          (click)="getVidePlayer(item.offlineCourse)"
                          scope="row"
                        >
                          <u> {{ item.offlineCourse.question }}</u>
                        </td>

                        <td>{{ item.offlineCourse.topic }}</td>

                        <td>{{ item.offlineCourse.videoDuration }} sec</td>
                        <td>{{ item.offlineCourse.videoDescription }}</td>
                        <td>
                          <button
                            (click)="editExtraVideo(item.offlineCourse)"
                            mat-icon-button 
                           
                            type="button"
                            title="Edit this video"
                          >
                          <mat-icon>border_color</mat-icon>
                          </button>
                        </td>
                        <td>
                          <button
                            (click)="deleteECAVideo(item.offlineCourse.idOfflineVideoCourse)"
                            mat-icon-button 
                            color="warn"
                            type="button"
                            title="Delete this video"
                          >
                          <mat-icon>delete</mat-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <pagination-controls
                    (pageChange)="pageChanged2($event)"
                  ></pagination-controls>
                </div>
              </div>
              <!-- 
              <ng-template *ngIf="!showExtraVideoFlag">
                <h2
                  style="
                    align-items: center;
                    align-content: center;
                    text-align: center;
                  "
                >
                  Its Look Like You have not attempted any quiz yet!
                </h2>
              </ng-template>
                 -->
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
