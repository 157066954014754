<h4 align="center">Academic Video Upload Panel</h4>
<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Product Info</ng-template>
      <mat-dialog-content class="mat-typography" style="height: 40rem;">
        <div class="row">
          <div class="col-sm-3">
            <mat-form-field style="width: 100%">
              <mat-label>Topic</mat-label>
              <input matInput placeholder="Enter Your Video Topic" formControlName="topic" required maxlength="40"/>
              <mat-error *ngIf="firstFormGroup.controls['topic'].errors?.maxLength">Max length exceeded</mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-4">
            <mat-form-field style="width: 100%">
              <mat-label>State</mat-label>
              <mat-select (selectionChange)="setState($event.value)" required formControlName="idState">
                <mat-option *ngFor="let state of stateData" [value]="state.idState">
                  {{ state.state}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            <mat-form-field style="width: 100%">
              <mat-label>Syllabus</mat-label>
              <mat-select (selectionChange)="setSyllabus($event.value)" required formControlName="idSyllabus">
                <mat-option *ngFor="let syllabus of syllabusData" [value]="syllabus.idSyllabus">
                  {{ syllabus.syllabusName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div *ngIf="classFlag" class="col-sm-2">
            <mat-form-field style="width: 100%">
              <mat-label>Class Standard</mat-label>
              <mat-select (selectionChange)="getAllSubjects($event.value)" required formControlName="classStandard">
                <mat-option *ngFor="let class of classData" [value]="class.idClassStandard">
                  {{ class.classStandadName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="subFlag" class="col-sm-3">
            <mat-form-field style="width: 100%">
              <mat-label>Subjects</mat-label>
              <mat-select required formControlName="subject" (selectionChange)="getSubjects($event.value)">
                <mat-option *ngFor="let subject of subjectData" [value]="subject.idSubject">
                  {{ subject.subjectName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="playlistFalg && subFlag" class="col-sm-3">
            <mat-form-field style="width: 100%">
              <mat-label>Chapter</mat-label>
              <mat-select required formControlName="chapter" (selectionChange)="getHeadings($event.value)">
                <mat-option *ngFor="let chapter of chapterData" [value]="chapter.idSubjectChapter">
                  {{ chapter.chapterName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="playlistFalg && subFlag" class="col-sm-3">
            <mat-form-field style="width: 100%">
              <mat-label>Video Sequence Number</mat-label>
              <input type="number" matInput placeholder="Enter Your Video Sequence Number."
                formControlName="videoSeqNum" min="1" required />
            </mat-form-field>
          </div>
        </div>
            <br />
            <div *ngIf="prodFlag" style="text-align: center">
              <h4 align="center">
                Selected Product: {{ productData.productName }}
              </h4>
            </div>

            
            <mat-label>Description</mat-label>
            <!-- <textarea formControlName="description" matInput matTextareaAutosize matAutosizeMinRows="1"
              placeholder="Video Description" maxlength="500">
                </textarea> -->
                <quill-editor  formControlName="description" name="description" trackChanges="all" [modules]="modules" style="height: 200px; display: block;">
                </quill-editor> 
         
          <br />
      </mat-dialog-content>
      <div>
        <mat-dialog-actions align="end">
          <button mat-raised-button color="primary" align="end" matStepperNext [disabled]="!firstFormGroup.valid">
            Next
          </button>
        </mat-dialog-actions>
      </div>
    </form>

  </mat-step>
  <mat-step [stepControl]="secondFormGroup" label="Video Info">
    <form [formGroup]="secondFormGroup">
      <div class="row" >
        <div class="col-sm-4"  >
            <mat-form-field style="width: 100%">
              <mat-label>{{parentFolder}}</mat-label>
              <mat-select formControlName="folder" (selectionChange)="getSubFolders($event.value)">
                <mat-option *ngFor="let folder of rootBoardFolders ; let i = index" [value]="folder">{{folder.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
         
        
          </div>
          <div class="col-sm-4" *ngIf="folderIndex===1 || folderIndex>1"  >
            <mat-form-field style="width: 100%">
              <mat-label>{{selectedBoardName}}</mat-label>
              <mat-select formControlName="folder" (selectionChange)="getSubFolders($event.value)">
                <mat-option *ngFor="let folder of gradeFolders ; let i = index" [value]="folder">{{folder.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
         
        
          </div>
          <div class="col-sm-4" *ngIf="folderIndex===2">
            <mat-form-field style="width: 100%">
              <mat-label>{{selectedGradeName}}</mat-label>
              <mat-select formControlName="folder" (selectionChange)="getSubFolders($event.value)">
                <mat-option *ngFor="let folder of subjectFolders ; let i = index" [value]="folder">{{folder.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
         
        
          </div>
        <div class="col-sm-8">
          <mat-form-field style="width: 100%">
            <mat-label>Heading</mat-label>
            <input matInput placeholder="Enter the Heading"   [matAutocomplete]="auto" formControlName="heading" required  maxlength="100"/>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.heading">
                {{option.heading}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="secondFormGroup.controls['heading'].errors?.required">This field is mandatory.</mat-error>
            <mat-error *ngIf="secondFormGroup.controls['heading'].errors?.maxlength">Maximum Character Length is 100.
            </mat-error>
          </mat-form-field>
        </div>
        <!-- <div class="col-sm-4" *ngIf="subFolder">
          <mat-form-field style="width: 100%">
            <mat-label>{{selectedSubFolderName}}</mat-label>
            <mat-select formControlName="parentFolder" (selectionChange)="getSubFolder($event.value)">
              <mat-option *ngFor="let folder of videoCipherFolders[folderIndex]; let j = index" [value]="folder">{{ folder.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </div>
      
      <div class="row"style="margin-top:10px">
        <div class="col-sm-6">
          <label for="file">Select Video Upload Option:</label>   
        </div>
        <div class="col-sm-3" *ngFor="let option of radioOptions">
          <div class="custom-control custom-radio">
            <input type="radio" id="customRadio1" formControlName="selectVideoType" required name="selectVideoType"
            [value]="option.value" (change)="selectedOption(option.value)"  class="custom-control-input" value="Design" class="custom-control-input">
            <label class="custom-control-label" for="customRadio1">{{ option.label }}</label>
          </div>
          <!-- {{'dsadadad '+uploadedVideoUrl}} -->
        </div>
      </div>

      <div class="row" style="padding:20px;border:1px solid #bfbfbf;border-radius: 15px;"  *ngIf="selectVideoType === 'file'" >
        <div class="col-sm-2">
          <label for="file">Video file</label>   
        </div>
        <div class="col-sm-6">
          <input type="file"id="file"placeholder="Upload video" accept=".mp4," (change)="uploadVideo($event)" formControlName="videoUrl">  
        </div>
        <div
        *ngIf="uploadedVideoUrl != null"
        style="
          background-color: #e0e0e0;
          background-color: #e0e0e0;
          width: 200px;
          border-radius: 20px;
          align-content: center;
          text-align: center;
          padding-bottom: 10px;
        "
      >
        <!-- <span style="color: black; font-weight: 500">
         PDF_URL
        </span> -->
        <a href="{{uploadedVideoUrl }}" target="_blank">View Video</a>

        <button
          (click)="removeVideo() "
          style="height: 10px; width: 10px"
          mat-icon-button
          aria-label="Example icon button with a vertical three dot icon"
        >
          <mat-icon style="font-size: 18px">cancel</mat-icon>
        </button>
      </div>
      </div>
      <div class="row" style="padding:20px;border:1px solid #bfbfbf;border-radius: 15px;"  *ngIf="selectVideoType === 'id'" >
        <div class="col-sm-12">
          <mat-form-field style="width: 100%">
            <mat-label>Enter Video ID</mat-label>
            <input matInput placeholder="Enter Video ID" #videoIdInput  formControlName="videoEnLink" required  maxlength="100"/>  
          </mat-form-field>  
        </div>
      </div>  

      <div class="row"style="margin-top:20px">
        <div class="col-sm-2">
          <label for="file">Subject Pdf</label>   
        </div>
        <div class="col-sm-6">
          <input type="file"id="file"placeholder="Upload file" accept=".pdf,.doc,.docx" (change)="uploadPdf($event)"formControlName="pdfURL">  
        </div>
        <div
        *ngIf="pdfURL != null"
        style="
          background-color: #e0e0e0;
          background-color: #e0e0e0;
          width: 200px;
          border-radius: 20px;
          align-content: center;
          text-align: center;
          padding-bottom: 10px;
        "
      >
        <!-- <span style="color: black; font-weight: 500">
         PDF_URL
        </span> -->
        <a href="{{pdfURL }}" target="_blank">View PDF</a>

        <button
          (click)="removepdf() "
          style="height: 10px; width: 10px"
          mat-icon-button
          aria-label="Example icon button with a vertical three dot icon"
        >
          <mat-icon style="font-size: 18px">cancel</mat-icon>
        </button>
      </div>
      </div>

      <br />

      <div class="row" style="padding:20px;border:1px solid #bfbfbf;border-radius: 15px;" >
        <div class="col-sm-2">
          <label for="file">Upload Teacher Note</label>   
        </div>
        <div class="col-sm-6">
          <input type="file"id="file"placeholder="Upload teacher note" accept=".pdf,.mp4,.mov,.wmv,.avi,.webm,.flv,.mkv" (change)="uploadTeacherNote($event)" formControlName="teacherNoteUrl">  
        </div>
        <div
        *ngIf="teacherNoteUrl != null"
        style="
          background-color: #e0e0e0;
          background-color: #e0e0e0;
          width: 200px;
          border-radius: 20px;
          align-content: center;
          text-align: center;
          padding-bottom: 10px;
        "
      >
        <!-- <span style="color: black; font-weight: 500">
         PDF_URL
        </span> -->
        <a *ngIf="teachNoteFileType=='document'" href="{{ teacherNoteUrl }}" target="_blank">View Teacher Note</a>
        <video  *ngIf="teachNoteFileType=='video'" width="100%" controls>
          <source src="{{teacherNoteUrl}}"/>
          Your browser does not support the video tag.
        </video>

        <button
          (click)="removeTeacherNote()"
          style="height: 10px; width: 10px"
          mat-icon-button
          aria-label="Example icon button with a vertical three dot icon"
        >
          <mat-icon style="font-size: 18px">cancel</mat-icon>
        </button>
      </div>
      </div>
      <br />

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field style="width: 100%">
            <mat-label>Question</mat-label>
            <input matInput placeholder="Enter the Question Here" formControlName="question" required />
            <mat-error *ngIf="secondFormGroup.controls['question'].errors?.required">This field is mandatory.
            </mat-error>
            <mat-error *ngIf="secondFormGroup.controls['question'].errors?.maxlength">Maximum Character Length is 500.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10">
          <mat-form-field style="width: 100%; height: 30px;">
            <mat-label>Answer</mat-label>
            <textarea required formControlName="answer" matInput matTextareaAutosize matAutosizeMinRows="1"
              placeholder="Enter your answer">
                  </textarea>

            <mat-error *ngIf="secondFormGroup.controls['answer'].errors?.required">This field is mandatory.</mat-error>
            <mat-error *ngIf="secondFormGroup.controls['answer'].errors?.maxlength">Maximum Character Length is 1500.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-2">
          <button mat-raised-button type="button" (click)="generateStringValue()" align="end">Generate</button>
        </div>
      </div>

      <div *ngIf="generatedValueFlag" style="position: relative;" class="row">

        <code> {{generatedValue}}</code>
        <button style="position: absolute;  right: 20px; top: 0px;" [attr.cdkCopyToClipboard]="generatedValue"
          title="Copy to clipboard" mat-icon-button type="button">
          <mat-icon style="color: darkgrey;">content_copy</mat-icon>
        </button>
      </div>

      <br />
      <div>
        <mat-dialog-actions>
          <button mat-raised-button matStepperPrevious>Back</button>
          <button mat-raised-button color="primary" align="end" matStepperNext [disabled]="!secondFormGroup.valid">
            Next
          </button>
        </mat-dialog-actions>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="thirdFormGroup" label="Multi-Language Video Info">
    <form [formGroup]="thirdFormGroup" flex layout="column" style="height: 100%;">
      <div class="row">
        <div style="position: relative;" class="col-sm-10">
          <mat-form-field style="width: 100%">
            <mat-label>Translated Text</mat-label>
              <textarea formControlName="insertText" matInput matTextareaAutosize matAutosizeMinRows="1"
                placeholder="Enter your text" style="height: 60px;">
              </textarea>
            </mat-form-field>

          <button style="position: absolute;  right: 20px; top: 0px;" (click)="onNavigateTranslator()"
            title="Open Google Translator" mat-icon-button type="button">
            <mat-icon style="color: darkgrey;">g_translate</mat-icon>
          </button>

        </div>


        <div class="col-sm-2">
          <button mat-raised-button type="button" (click)="insertText()" align="end"
            style="padding-top:5px;">Insert</button>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>Language</mat-label>
            <mat-select formControlName="language">
              <mat-option *ngFor="let data of languageList; let j = index" [value]="data.language">{{ data.language }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div class="col-sm-8">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let subChip of videoLinks; let k = index" [selectable]="selectable"
              [removable]="removable" (removed)="removeSub(subChip, k)">
              {{ subChip.language }}&nbsp;
              <a *ngIf="subChip.pdfURL && subChip.pdfURL!=''" href="{{subChip.pdfURL}}" target="_blank"> PDF_URL</a>
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>

      </div>
      
      <!-- <div class="row">
        <div class="col-sm-4">
          <div *ngIf="linkPage">
            <mat-form-field style="width: 100%">
              <mat-label>Folders</mat-label>
              <mat-select formControlName="folder">
                <mat-option *ngFor="let folder of videoCipherFolders; let j = index" [value]="folder.id">{{ folder.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-8">
          <mat-form-field style="width: 100%">
            <mat-label>Heading</mat-label>
            <input matInput placeholder="Enter the Heading" formControlName="heading" required />
            <mat-error *ngIf="thirdFormGroup.controls['heading'].errors?.required">This field is mandatory.</mat-error>
            <mat-error *ngIf="thirdFormGroup.controls['heading'].errors?.maxlength">Maximum Character Length is 100.
            </mat-error>
          </mat-form-field>
        </div>
      </div> -->

      <div class="row"style="margin-top:10px">
        <div class="col-sm-2">
          <label for="file">Language Pdf</label>   
        </div>
        <div class="col-sm-4">
          <input type="file"id="file"placeholder="Upload file" accept=".pdf,.doc,.docx" (change)="uploadLanguagePdf($event)" formControlName="langPdf">  
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-form-field style="width: 100%">
            <mat-label>Question</mat-label>
            <input matInput placeholder="Enter the Question Here" formControlName="question" required />
            <mat-error *ngIf="thirdFormGroup.controls['question'].errors?.required">This field is mandatory.</mat-error>
            <mat-error *ngIf="thirdFormGroup.controls['question'].errors?.maxlength">Maximum Character Length is 500.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10">
          <mat-form-field style="width: 100%">
            <mat-label>Answer</mat-label>
            <textarea required formControlName="answer" matInput matTextareaAutosize matAutosizeMinRows="1"
              placeholder="Enter your answer">
                  </textarea>

            <mat-error *ngIf="thirdFormGroup.controls['answer'].errors?.required">This field is mandatory.</mat-error>
            <mat-error *ngIf="thirdFormGroup.controls['answer'].errors?.maxlength">Maximum Character Length is 1500.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-2" align="center" style="padding-top: 15px;">
          <button mat-icon-button type="button" (click)="addVideoLink()">
            <mat-icon style="font-size: 40px;"> add_circle_outline</mat-icon>
          </button>
        </div>
      </div>

      <br />
      <div>
        <mat-dialog-actions>
          <button mat-raised-button matStepperPrevious>Back</button>
          <button [disabled]="!firstFormGroup.valid && !secondFormGroup.valid" (click)="createVideo()" mat-raised-button
            color="primary" align="end" matStepperNext type="button">
            Submit
          </button>

        </mat-dialog-actions>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
<ng-template #videoDialog>
  <div mat-dialog-content>
    <h4>Video Preview</h4>
    <video width="100%" controls>
      <source src="assets/sample-video.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </div>
</ng-template>